import React, { Component } from "react"
import TrustPilotStar from "../images/tp-star.svg";

class StarRating extends Component {
    stars = +this.props.stars;

    createStar() {
        if (this.stars >=1 ){
            this.stars--;
            return '#489a41';
        } else{
            if (this.stars > 0){
                let rate = Math.round(this.stars * 100)
                this.stars--;
                return 'linear-gradient(to right, #489a41 ' + rate + '%, #dadce4 ' + rate + '%)'
            }
            else{
                return '#dadce4';
            }
        }
    }

    render() {
        return(
            <div className="d-flex mr-2">
                <div className="tp-star" style={{background: this.createStar(this.stars)}}>
                    <img src={TrustPilotStar} alt=""/>
                </div>
                <div className="tp-star" style={{background: this.createStar(this.stars)}}>
                    <img src={TrustPilotStar} alt=""/>
                </div>
                <div className="tp-star" style={{background: this.createStar(this.stars)}}>
                    <img src={TrustPilotStar} alt=""/>
                </div>
                <div className="tp-star" style={{background: this.createStar(this.stars)}}>
                    <img src={TrustPilotStar} alt=""/>
                </div>
                <div className="tp-star" style={{background: this.createStar(this.stars)}}>
                    <img src={TrustPilotStar} alt=""/>
                </div>
            </div>
        )
    }
}

export default StarRating
